<template lang="pug">
#transfer.routes
  .main-wrapper(v-if="init")
    TransferHero(:title="`Transfer From <br> ${fromDestination.name} to ${$route.params.to}`")
      TransferSearchForm.mt-4(
        :from="$route.params.from"
        :to="$route.params.to"
        @e-reload-routes="loadRoutes"
      )
    .container-lg.pt-4
      //- h2.mb-4 Transfer From {{ fromDestination.name }} to {{ $route.params.to }}
      .route-list-wrapper
        .routes(v-for="(route, rKey) in source.routes" :key="rKey")
          RouteListComponent(
            v-for="(pack, pKey) in route.packages"
            :key="pKey"
            :route="route"
            :pax="pax"
            :package="pack"
          )
      //- .d-block(v-for="(route, rKey) in source.routes" :key="rKey")
      //-   strong {{ route.sale_label }}
      //-   pre {{ route.prices }}
      //- pre {{ fromDestination }}
      //- pre {{ source }}
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import TransferHero from '@/components/TransferHero.vue'
import TransferSearchForm from '@/components/TransferSearchForm.vue'
import RouteListComponent from '@/components/RouteListComponent.vue'

const END_POINT = 'external-transfer/v1/transfer'

export default {
  name: 'Home',
  components: {
    TransferHero,
    TransferSearchForm,
    RouteListComponent
  },
  data: () => ({
    init: false,
    pax: 2,
    source: {
      destinations: [],
      routes: []
    }
  }),
  computed: {
    fromDestination () {
      const vm = this
      const destination = vm.source.destinations.filter(obj => {
        // console.log(obj.slug)
        return obj.slug === vm.$route.params.from
      })[0]
      if (destination) {
        return destination
      }
      return []
    }
  },
  mounted () {
    this.pax = this.$route.query.pax
    this.loadRoutes()
  },
  methods: {
    loadRoutes () {
      const vm = this
      const query = {
        from: vm.$route.params.from,
        to: vm.$route.params.to,
        pax: vm.$route.query.pax
      }

      axios.post(`${END_POINT}/routes`, query)
        .then((r) => {
          vm.source = r.data.success ? r.data.data : []
          vm.init = true
        })
      vm.pax = vm.$route.query.pax
    }
  }
}
</script>
